<template>
  <!-- 编辑 -->
  <div class="addPerson__wrap" v-show="visible">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="handleReturn">
          <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
          <span>返回</span>
        </div>
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.title }}
          <span> > </span>
          编辑采购单
        </div>
      </div>
    </el-header>

    <div class="main">
      <div class="mainBox" style="margin-bottom:0px;">
        <div class="itemTime">基础信息</div>
        <el-descriptions title="" >
          <el-descriptions-item label="采购来源">
            <span>{{info.source == 1 ? '销售订单' : '平台自采'}}</span>
          </el-descriptions-item>
          <el-descriptions-item label="计划交货日期">{{info.delivery_date}}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{info.create_time}}</el-descriptions-item>
          <el-descriptions-item label="采购单号">{{info.order_no}}</el-descriptions-item>
          <el-descriptions-item label="采购状态">{{ toStr({0:'待发货',1:'待收货', 2:'已取消', 3:'部分收货', 4:'全部收货'}, info.status)}}</el-descriptions-item>
          <el-descriptions-item label="地区">{{info.city_name}}</el-descriptions-item>
          <el-descriptions-item label="仓库名称" v-if="!!info.stash_name">{{info.stash_name}}（{{info.stash_province}}{{info.stash_city}}）</el-descriptions-item>
          <el-descriptions-item label="供应商/采购员" v-if="!!info.supplier_name">{{info.supplier_name}}</el-descriptions-item>
        </el-descriptions>

        <div class="itemTime">采购商品<span style="color:#999;font-size:14px;"> (共{{table.total}}项)</span></div>
        <!-- <el-input
          style="width:240px;margin-bottom:10px;"
          v-model.trim="form.data.goods_name"
          placeholder="请输入商品名称"
          size="small"
          clearable
          @clear="getTable"
          @keydown.enter.native="getTable"
        >
          <el-button slot="append" icon="el-icon-search" @click="getTable"></el-button>
        </el-input> -->
        <!-- is_sorter:1：是， 若供应商是支持代分拣的收货登记增加了食堂名称字段 -->
        <!-- <el-select 
          v-if="info.is_sorter == 1"
          v-model="form.data.address_id" 
          filterable placeholder="请输入食堂名称" 
          style="width:240px;margin-bottom:10px;margin:0 10px;"
          clearable 
          size="small"
          @focus="getStore" 
          @change="getTable">
          <el-option v-for="item in storeArr" :key="item.id" :label="item.name" :value="item.id">
            <div>
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">({{ item.merchant_name }})</span>
            </div>
          </el-option>
        </el-select> -->

        <el-table
          :data="tableData"
          style="width: 100%"
          border
          :cell-style="{background:'#fff'}"
          :header-cell-style="{background:'#f5f5f5'}"
          :span-method="objectSpanMethod"
          @selection-change="handleSelectionChange">
          <!-- <el-table-column
            type="selection"
            width="55"
            :selectable="selectEnable" fixed>
          </el-table-column> -->
          <el-table-column
            width="80">
            <template slot="header" slot-scope="{}">
              <i class="el-icon-s-fold"></i>
            </template>
            <template slot-scope="{row, $index}">
              <div style="display:flex;align-items:center;">
                <el-popconfirm title="确认删除此商品？" @confirm="onMinus(row,$index)">
                  <el-button slot="reference" plain style="width: 22px;height: 18px;padding: 0;">-</el-button>
                </el-popconfirm>
                <el-button plain style="margin-top:1px;margin-left:5px;display:block;width: 22px;height: 18px;padding: 0;" @click="onAdd(row,$index)">+</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column type="index" label="ID" ></el-table-column>
          <el-table-column
            prop="good_img"
            label="商品名称"
            width="180px"
            key="1">
            <template slot-scope="{row,$index}">
              <div style="display: flex;align-items: center;" v-if="!!row.id">
                <el-image
                  v-if="!!row.image"
                  style="width: 40px; height: 40px"
                  :src="row.image"
                  fit="cover"
                  :preview-src-list="[row.image]"></el-image>
                <el-image
                  v-else
                  style="width: 40px; height: 30px"
                  :src="require('@/assets/img/logo-icon.jpeg')"
                  fit="cover"
                  :preview-src-list="[require('@/assets/img/logo-icon.jpeg')]"></el-image>
                <span style="margin-left:10px;">{{row.good_name}}</span>
              </div>
              <template v-else>
                <el-select
                  v-model="row.good_name"
                  filterable
                  remote
                  placeholder="请输入关键词"
                  :remote-method="remoteMethod"
                  :loading="selLoading"
                  :ref="`multiSelect+${$index}`"
                  size="small"
                  @change="changeGoods($event,$index)" value-key="id">
                  <el-option v-for="item in goodArr" :label="item.good_name" :value="item" :key="item.id">
                    <div>
                      <span style="float: left">{{ item.good_name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.name }}</span>
                    </div>
                    <div style="color:#999;margin-top: -5px;" v-if="!!item.norm_name">标名：{{item.norm_name}}</div>
                  </el-option>
                </el-select>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="code"
            label="商品编码"
            width="120px"
            key="2">
          </el-table-column>
          <el-table-column
            prop="order_cate_name"
            label="下单分类"
            width="120"
            key="3"
            min-width="120px">
            <template slot-scope="{row}">
              <span>{{row.order_cate_name}}</span>
              <span v-if="!!row.order_tow_name"> / {{row.order_tow_name}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="unit_name"
            label="单位"
            width="100px"
            key="4">
          </el-table-column>
          <el-table-column
            prop="name"
            label="规格"
            width="120px"
            key="5">
          </el-table-column>
          <!-- 不支持分拣的供应商数据，不需要显示食堂名称字段 -->
          <el-table-column
            v-if="info.is_sorter == 1"
            prop="store_name"
            label="*食堂名称"
            width="140px"
            key="6">
            <template slot-scope="{row}">
              <div v-if="!!row.store_name">{{row.store_name}}</div>
              <div v-else>
                <el-select v-model="row.address_id" filterable @focus="getStore" size="mini">
                  <el-option v-for="item in storeArr" :key="item.id" :label="item.name" :value="item.id">
                    <div>
                      <span style="float: left">{{ item.name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">({{ item.merchant_name }})</span>
                    </div>
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="purchase_price"
            label="近一次采购价"
            width="110px"
            key="8">
          </el-table-column>
          <el-table-column
            prop="num"
            label="*下单数量"
            min-width="120px"
            key="10">
            <template slot-scope="{row,$index}">
              <el-input v-model="row.num" placeholder="请输入" size="small" @input="numREG_EXP(row,$index)"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="remarks"
            label="备注"
            min-width="180px"
            key="11">
            <template slot-scope="{row}">
              <div v-for="(v,i) in row.remarks" :key="i">
                <el-input v-model="v.context" autosize placeholder="请输入（限50字）" size="small" type="textarea" maxlength="50"></el-input>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="after-table">合计：商品项数 {{table.total}}    计划采购数量 {{totalNumAll}}</div>
      </div>
      <div class="footerWrap">
        <el-button size="medium" @click="toggle(false)">取消</el-button>
        <!-- <el-button type="primary" plain size="medium" @click="showSupplier">修改供应商</el-button> -->
        <el-button type="primary" size="medium" @click="confirm">保存</el-button>
      </div>
    </div>

    <!-- 修改供应商 -->
    <supplier-edit
      ref="supplierEdit"
      :setDataApi="setDataApi"
      @refresh="getTable"
    ></supplier-edit>

  </div>
</template>

<script>
  import SupplierEdit from '@/components/SupplierEdit.vue'
  import { mixinTable } from "@/mixins/table.js";
  import { getStorage } from '@/storage'
  export default {
    name: 'purchaseListEdit',
    mixins: [mixinTable],
    components: {
      SupplierEdit
    },
    data() {
      return {
        setDataApi: '',
        token: getStorage('token'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        info: {
          is_sorter: '', // 该供应商是否支持代分拣 1：是，2否
        },
        currRow: {
          order: {}
        },
        table: {
          total:0,
          num: 0
        },
        multipleSelection: [],
        tableData: [
          {
            good_img: require('@/assets/img/lb.png'),
            good_name:'小白菜',
            num:'',
            remarks: [{context:''}], // 备注
          }
        ],
        specArr: [],
        storeArr: [],
        loading: false,
        form: {
          loading: false,
          data: {
            goods_name: '',
            address_id: '',
            area: []
          },
          rules: {
            content: [
              { required: true, message: "请输入", trigger: "blur" },
            ],
          },
        },
        fileList: [],
        isShowDialog: false,

        pos:0,
        spanArr: [],// 合并单元格
        selLoading: false,
        goodArr: [],
        totalNumAll: 0, // 计划采购总数量
        
      }
    },
    methods: {
      async getDetail(row) {
        this.currRow = row
        await this.getTable();
      },
      getTable() {
        let _params = {
          id: this.currRow.id,
          goods_name: this.form.data.goods_name,
          address_id: this.form.data.address_id,
          page:1,
          count:1000
        }
        this.$http.get('/admin/purchase_order/info', { params: _params}).then(res => {
          if(res.code == 1) {
            this.form.data.area = [res.data.main.province, res.data.main.city];
            this.info = res.data.main;
            this.totalNumAll = res.data.main.num;
            let arr = res.data.details.list.map(v => {
              return {
                ...v,
                id: v.spec_id, // 规格id
                name: v.spec_name, // 规格名称
                image: v.goods_image, // 商品图片
                good_name: v.goods_name,// 商品名称
                purchase_price: v.last_purchase_price, // 最后一次采购价
              }
            })
            this.tableData = arr;

            // 支持分拣供应商且来源为销售订单的商品去重
            if(this.info.is_sorter == 1 && this.info.source == 1) {
              let some = [];
              this.tableData.forEach(el => {
                if (!some.some(e => (e.code == el.code && e.spec_name == el.spec_name))) {
                  some.push(el);
                }
              });
              this.table.total = some.length;
            } else {
              this.table.total = res.data.details.total;
            }

            this.pos = 0; // 初始化合并单元格数据
            this.spanArr = []; // 初始化合并单元格数据
            this.getSpanArr(this.tableData)
          }
        })
      },
      reset(done) {
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 获取食堂
      getStore() {
        this.$http.get('/admin/path/storeList', {params: {page:1,count:10000}}).then(res => {
          if(res.code === 1) {
            this.storeArr = res.data.list;
          }
        })
      },
      // 减
      onMinus(row,index) {
        console.log(row,index)
        if(this.tableData.length > 1) {
          this.tableData.forEach((v,i) => {
            if(index == i) {
              this.tableData.splice(i, 1)
            }
          })
          this.specArr = this.specArr.filter(j => j != row.id);
        } else {
          this.$message.warning('最后一条数据了!')
        }

        this.pos = 0; // 初始化合并单元格数据
        this.spanArr = []; // 初始化合并单元格数据
        this.getSpanArr(this.tableData)
        this.table.total -= 1; // 商品项数 减1
        this.totalNumAll = this.countNum(this.tableData); //计划采购总数量
      },
      // 加
      onAdd(row,index) {
        let str = {
          image: require('@/assets/img/default.jpg'),
          good_name:'',
          num:'',
          remarks:[{context:''}],
        }
        this.tableData.push(str)
        this.$nextTick(() => {
          this.$refs[`multiSelect+${index + 1}`].focus()
        })
        this.goodArr = []; //清除上次的查询商品数据
        this.pos = 0; // 初始化合并单元格数据
        this.spanArr = []; // 初始化合并单元格数据
        this.getSpanArr(this.tableData)
        this.table.total += 1; // 商品项数 加1
      },
      // 选择商品
      changeGoods(value, index) {
        let newSpecid = []
        newSpecid = this.tableData.map(v => { return v.id })
        this.specArr = newSpecid;
        if(this.specArr.indexOf(value.id) >= 0) {
          this.$message.warning("已经添加过该规格");
          this.tableData[index].good_name = {}; // 当前行搜索值清空
          this.goodArr = []; // 当前行筛选数据清空
          return;
        }
        this.specArr.push(value.id);
        let _currRow = {...value,num: '',remarks:[{context:''}]};
        this.tableData[index] = _currRow;
        this.$set(this.tableData, index, {..._currRow})
      },
      remoteMethod(query) {
        if(query !== '') {
          if(this.form.data.area.length < 1) {
            this.$message.warning('请先选择下单地区')
            return
          }
          this.selLoading = true;
          let _params = {
            province: this.form.data.area[0],
            city: this.form.data.area[1],
            page: 1,
            count: 1000,
            status:1,
            good_name: query
          }
          this.$http.get('/admin/goods/specList', { params:_params}).then(res => {
            if(res.code === 1) {
              this.goodArr = res.data.list;
            }
          }).finally(() => {
            this.selLoading = false;
          })
        } else {
          this.goodArr = [];
        }
      },
      // 计划采购数量
      countNum(arr) {
        let sum = 0;
        arr.map(function (item) {
          sum += Number(item.num)
        })
        return sum.toFixed(2) * 1
      },
      // 保存
      confirm() {
        let is_id = this.tableData.some((item) => !item.id); // 检测提交的数据是否选了商品
        let is_num = this.tableData.some((item) => !item.num); // 检测提交的数据是否填了商品规格购买数量
        let is_address_id = this.tableData.some((item) => !item.address_id); // 检测提交的数据是否选了门店
        if(is_id == true) {
          this.$message.warning("请选择要添加的商品")
          return
        }
        // 如果支持待分拣需要选择门店
        if(is_address_id == true && this.info.is_sorter == 1) {
          this.$message.warning("请选择食堂名称")
          return
        }
        if(is_num == true) {
          this.$message.warning("请输入商品规格下单数量")
          return
        }
        let _spec = this.tableData.map(v => {
          return {
            spec_id: v.id, //规格ID
            address_id: !!v.address_id ? v.address_id : '', //门店ID
            num: v.num, //购买数量
            remark: v.remarks.length > 0 ? v.remarks.filter(item => !!item.context).flatMap(j => Object.values(j)) : '' // 获取备注值不为空时，数组value集
          }
        })
        console.log(_spec)
        let _params = {
          id: this.info.id,
          spec: _spec
        }
        console.log(_params,"_params")
        // return
        this.$http.post('/admin/purchase_order/edit', _params).then(res => {
          if(res.code == 1) {
            this.toggle(false)
            this.$emit("refresh")
            this.$message.success('操作成功！')
          }
        })
      },

      numREG_EXP(row,index) {
        const reg = /^[\d]+$/;
        // 下单数量单位只有是（克、两、斤、千克）的时候可以输小数点一位小数，其他的请控制不允许输小数点
        if(row.unit_name == '克' || row.unit_name == '两' || row.unit_name == '斤' || row.unit_name == '千克') {
          if (row.num && !reg.test(row.num)) {
            row.num = row.num=row.num.match(/\d+(\.\d{0,1})?/) ? row.num.match(/\d+(\.\d{0,1})?/)[0] : '';
          }
        } else{
          if (row.num && !reg.test(row.num)) {
            row.num = row.num.replace(/[^\d]/g, '');
          }
        }
        this.totalNumAll = this.countNum(this.tableData);
      },

      getSpanArr(data) {
        // data 是我们从后台拿到的数据
        for(var i = 0; i < data.length; i++) {
          if(i == 0) {
            this.spanArr.push(1);
            this.pos = 0;
          } else {
            // 判断当前元素与上一个元素是否相同 (商品编码和规格一至的需要合并)
            if((data[i].code == data[i -1].code) && (data[i].spec_name == data[i -1].spec_name) && (data[i].good_name != '')) {
              this.spanArr[this.pos] += 1;
              this.spanArr.push(0);
            } else {
              this.spanArr.push(1);
              this.pos = i;
            }
          }
        }
      },
      // 当前行row、当前列column、当前行号rowIndex、当前列号columnIndex四个属性
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        // 支持分拣供应商且来源为销售订单的数据合并单元格
        if(this.info.is_sorter == 1 && this.info.source == 1 ) {
          if(columnIndex < 6  && columnIndex > 1) {
            const _row = this.spanArr[rowIndex];
            const _col = _row > 0 ? 1 : 0;
            // console.log(`rowSpan:${_row}, colspan:${_col}`)
            return {
              // [0,0] 表示这一行不显示，[2,1]标示这一行的合并数
              rowspan: _row,
              colspan: _col,
            }
          }
        }
      },
      // 批量选择数据
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      selectEnable(row) {
        if(row.status != 5) {
          return true
        }
      },
      // 返回
      handleReturn() {
        this.multipleSelection = [];
        this.$emit("refresh")
        this.toggle(false)
      },
      // 修改供应商/采购员
      showSupplier() {
        if(this.multipleSelection.length > 0) {
          let dom = this.$refs.supplierEdit;
          let job = {
            id: this.multipleSelection.map(v => { v.id })
          }
          dom.toggle(true);
          dom.getDetail(job);
          dom = null;
        } else {
          this.$message.warning("请选择商品")
        }
      },
     
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
} 
.addPerson__wrap .main {
  overflow-x: hidden;
  background: #fff;
}
.returnBtn {
  z-index: 99;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
}
.footerWrap {
  text-align: center;
  padding: 10px;
   box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
  z-index: 99;
}
.itemTime {
  display: block;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.flex {
  display: flex;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.width200px {
  width: 200px;
}
.after-table {
  padding: 10px;
  font-size: 14px;
  color: #303030;
  text-align: right;
}

::v-deep .el-table tbody tr:hover>td {
  background-color:#fff !important
}
.el-table tbody tr:hover>td {
  background-color:#fff !important
}
.el-select-dropdown__item {
  display: flex;
  flex-direction: column;
  height: auto !important;
}
</style>
