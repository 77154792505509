<template>
  <el-dialog
    append-to-body
    width="850px"
    top="3vh"
    title="批量添加"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    >
    <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
      <el-form-item prop="bind_id">
        <el-select v-model="table.params.bind_id" filterable id="bind_id" placeholder="请选择供应商/采购员" clearable @change="onSearch">
          <el-option v-for="item in supplierArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="good_name">
        <el-input v-model="table.params.good_name" placeholder="请输入商品名称" clearable @keydown.enter.native="onSearch"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
        <el-button icon="el-icon-refresh-right" @click="reset" >重置</el-button>
      </el-form-item>
      <div style="margin-top:5px;" >
        <span v-for="(v,index) in orderCateOpt" :key="v.id" :class="index == cateIndex ? 'cate-item active' : 'cate-item'" @click="handleCate(index,v)">
          {{v.name}}
        </span>
      </div>
      <div style="margin-top:5px;" v-if="cateIndex != 0">
        <span v-for="(j,index2) in orderCateOpt[cateIndex].child" :key="j.id" :class="index2 == cateIndex2 ? 'cate-item active' : 'cate-item'" @click="handleCate2(index2,j)" >
          {{j.name}}
        </span>
      </div>
    </el-form>
    
    <el-table 
      ref="multipleTable" 
      :data="table.data" 
      border 
      v-loading="table.loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      @selection-change="handleSelectionChange" 
      :row-key="row => row.id" 
      class="mt20" 
      :max-height="500">
      <el-table-column
        type="selection"
        :reserve-selection="isSelShow"
        :selectable="selectEnable"
        width="55">
      </el-table-column>
      <el-table-column property="no" label="名称编码" width="180">
        <template slot-scope="{row}">
          <div>
            {{row.good_name}}
            <!-- sold_out:1-售罄；0-有货 -->
            <span v-if="row.sold_out == 1" style="color:#f56c6c">(售罄)</span>
          </div>
          <div v-if="!!row.norm_name" style="color:#999;">标名：{{row.norm_name}}</div>
          <div>{{row.code}}</div>
        </template>
      </el-table-column>
      <el-table-column property="good_img" label="图片" width="80">
        <template slot-scope="{row}">
          <el-image
            v-if="!!row.image"
            style="width: 40px; height: 40px"
            :src="row.image"
            fit="cover"
            :preview-src-list="[row.image]"></el-image>
          <el-image
            v-else
            style="width: 40px; height: 40px"
            :src="require('@/assets/img/logo-icon.jpeg')"
            fit="cover"
            :preview-src-list="[require('@/assets/img/logo-icon.jpeg')]"></el-image>
        </template>
      </el-table-column>
      <el-table-column property="unit_name" label="单位"></el-table-column>
      <el-table-column property="name" label="规格"></el-table-column>
      <el-table-column property="order_cate_name" label="下单分类">
        <template slot-scope="{row}">
          <span>{{row.order_cate_name}}</span>
          <span v-if="!!row.order_two_name"> / {{row.order_two_name}}</span>
        </template>
      </el-table-column>
      <el-table-column property="price" label="售价/元"></el-table-column>
      <el-table-column property="num" label="下单数量" width="140px">
        <template slot-scope="{row}">
          <el-input v-model="row.num" placeholder="请输入" size="small" @input="numREG_EXP(row)"></el-input>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageWrap" v-if="table.total > 10">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="table.params.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="table.params.count"
        layout="total, sizes, prev, pager, next, jumper"
        :total="table.total">
      </el-pagination>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button  size="small" @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="confirm" size="small" >确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getStorage } from '@/storage'
  import { mixinTable } from '@/mixins/table.js'
  export default {
    name: 'BatchGoodsAdd',
    props: {
      merchant_id: { required: false, type: Number, default: 0 }, // 商户id （添加订单时需要传）
      address_id: { required: false, type: Number, default: 0 }, // 食堂id （添加订单时需要传）
      area: { required: false, type:Array, default: () => [] }, // 下单地区（添加采购单时传）
    },
    mixins: [mixinTable],
    data() {
      return {
        token: getStorage('token'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: true,
        multipleSelection: [],
        table: {
          params:{
            page:1,
            count:10,
            status: 1, // 1：在架商品 
            bind_id: '', // 供应商/采购员
            order_cate_id: '', // 下单分类
            order_cate_tow: '', // 二级下单分类
            good_name: '', // 商品名称
          },
          total: 0,
          data:[],
        },
        isSelShow: true,
        orderCateOpt: [], // 下单分类
        supplierArr: [], // 供应商/采购员
        cateIndex: 0,//下单分类索引
        cateIndex2: 0,//二级下单分类索引
      }
    },
    created() {
      this.getTable();
      this.getOrderCate(); // 下单分类
      this.getSupplierSel(); // 供应商/采购员
    },
    methods: {
      // 重置查询条件
      reset() {
        this.$refs.elFormDom.resetFields()
        this.table.params.page = 1;
        this.table.params.count = 10;
        this.table.params.order_cate_id = ''; // 下单分类id
        this.cateIndex = 0; // 回到全部
        this.cateIndex2 = 0; // 回到全部
        this.getTable();
      },
      // 获取商品列表
      getTable() {
        let _params = {
          ...this.table.params,
          province: this.area.length > 1 ? this.area[0] : '',
          city: this.area.length > 1 ? this.area[1] : '',
        }
        _params.merchant_id = this.merchant_id || '';
        _params.address_id = this.address_id || '';
        this.table.loading = true;
        this.$http.get("/admin/goods/specList",{params: _params}).then((res) => {
          if (res.code === 1) {
            this.table.loading = false;
              console.log(this.multipleSelection,"this.multipleSelection")
            this.table.data = res.data.list.map(v => {
              let _multipleSelection = this.multipleSelection.filter(item => item.id == v.id);
              return {
                num:_multipleSelection.length ? _multipleSelection[0].num : 1,
                ...v
              }
            });
            this.table.total = res.data.total;
          }
        })
      },
      // 下单分类
      getOrderCate() {
        this.$http.get('/admin/mini_cate/list', {params:{page:1,count:1000}}).then(res => {
          if(res.code === 1) {
            let str = {
              id: '',
              name: '全部',
              child:[]
            }
            this.orderCateOpt = res.data.list.map(v => {
              return {
                ...v,
                child: v.child.length > 0 ? [{id:'',name:'全部'}, ...v.child] : v.child
              }
            });
            this.orderCateOpt.unshift(str);
          }
        })
      },
      // 下单分类查询
      handleCate(index,v) {
        this.cateIndex = index;
        this.table.params.order_cate_id = v.id;
        this.table.params.order_cate_tow = '';
        this.getTable();
      },
      // 二级下单分类查询
      handleCate2(index2,j) {
        this.cateIndex2 = index2;
        this.table.params.order_cate_tow = j.id;
        this.getTable();
      },
      // 批量选择
      handleSelectionChange(val) {
        this.multipleSelection = val;
        console.log(this.multipleSelection)
      },
      // 提交
      confirm() {
        console.log(this.multipleSelection,"this.multipleSelection")
        if(this.multipleSelection.length > 0) {
          this.$emit('sendgoodslist',this.multipleSelection.map(v => {
            return {
              ...v,
              num: Number(v.num),
              type:1,
            }
          }))
        } else {
          this.$alert('请选择需要添加的商品', '', {
            confirmButtonText: '确定',
            type: 'error',
            callback: action => {
             
            }
          });
        }
      },
      numREG_EXP(row) {
        const reg = /^[\d]+$/;
        // 下单数量单位只有是（克、两、斤、千克）的时候可以输小数点一位小数，其他的请控制不允许输小数点
        if(row.unit_name == '克' || row.unit_name == '两' || row.unit_name == '斤' || row.unit_name == '千克') {
          if (row.num && !reg.test(row.num)) {
            row.num = row.num=row.num.match(/\d+(\.\d{0,1})?/) ? row.num.match(/\d+(\.\d{0,1})?/)[0] : '';
          }
        } else{
          if (row.num && !reg.test(row.num)) {
            row.num = row.num.replace(/[^\d]/g, '');
          }
        }
      },
      // 取消 
      handleClose() {
        this.$parent.isShowDialog = false;
      },
      selectEnable(row) {
        //售罄的禁用选择 sold_out:1-售罄；0-有货
        if(row.sold_out !== 1) {
          return true
        }
      },
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
} 
.footerWrap {
  text-align: center;
  padding: 10px;
   box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
  z-index: 99;
}
.itemTime {
  display: block;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.flex {
  display: flex;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.width200px {
  width: 200px;
}
.pageWrap {
  margin-top: 10px;
  text-align: center;
}
.cate-item {
  height: 26px;
  line-height: 26px;
  border-radius: 6px;
  padding: 0 10px;
  border: solid 1px #eee;
  margin: 0 5px;
  cursor: pointer;
  display: inline-block;
  margin-top: 5px;
}
.active {
  background: #eaf7f0;
  border: solid 1px #2cb167;
  color: #2cb167;
}
</style>
