<template>
  <!-- 新增 -->
  <div class="addPerson__wrap" v-show="visible">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="handleReturn">
          <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
          <span>返回</span>
        </div>
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.title }}
          <span> > </span>
          {{isCopy == true ? '复制采购单' : '添加采购单'}}
        </div>
      </div>
    </el-header>

    <div class="main">
      <div class="mainBox">
        <div class="itemTime">基础信息</div>
        <el-form
          class="staff-from"
          v-loading="loading"
          element-loading-text="加载中…"
          ref='elFormDom'
          label-position='right'
          :model='form.data'
          :rules='form.rules'
          :size="subUnitSize"
          :inline="true"
        >
          <el-form-item label="计划交货日期" prop="delivery_date">
            <el-date-picker
              v-model="form.data.delivery_date"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="下单地区" prop="area">
            <el-cascader
              v-model="form.data.area"
              :options="areaArr"
              :props="{ checkStrictly: false }"
              clearable
              filterable
              style="width:220px">
            </el-cascader>
          </el-form-item>
          <el-form-item label="所在仓库" prop="stash_id">
            <el-select v-model="form.data.stash_id" filterable placeholder="请输入">
              <el-option
                v-for="item in stashArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                <div>
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.province_str }}{{item.city_str}}</span>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <div class="itemTime mt10">商品清单</div>
        <el-button type="primary" class="mb20" plain size="small" @click="batchAdd">批量添加</el-button>
        <el-table
          :data="tableData"
          :header-cell-style="{background:'#f5f5f5'}"
          style="width: 100%"
          border
          >
          <el-table-column
            width="80">
            <template slot-scope="{}">
              <i class="el-icon-menu"></i>
            </template>
          </el-table-column>
          <el-table-column
            width="80">
            <template slot="header" slot-scope="{}">
              <i class="el-icon-s-fold"></i>
            </template>
            <template slot-scope="{row, $index}">
              <el-popconfirm title="确认删除此商品？" @confirm="onMinus(row,$index)">
                <el-button slot="reference" plain style="width: 22px;height: 18px;padding: 0;">-</el-button>
              </el-popconfirm>
              <el-button plain style="display:block;width: 22px;height: 18px;padding: 0;" @click="onAdd(row,$index)">+</el-button>
            </template>
          </el-table-column>
          <el-table-column
            prop="good_img"
            label="商品图片">
            <template slot-scope="{row}">
              <el-image
                v-if="!!row.image"
                style="width: 40px; height: 40px"
                :src="row.image"
                fit="cover"
                :preview-src-list="[row.image]"></el-image>
              <el-image
                v-else
                style="width: 40px; height: 40px"
                :src="require('@/assets/img/logo-icon.jpeg')"
                fit="cover"
                :preview-src-list="[require('@/assets/img/logo-icon.jpeg')]"></el-image>
            </template>
          </el-table-column>
          <el-table-column
            prop="good_name"
            label="商品名称"
            width="180">
            <template slot-scope="{row,$index}">
              <el-select
                v-model="row.good_name"
                filterable
                remote
                placeholder="请输入关键词"
                :remote-method="remoteMethod"
                :loading="selLoading"
                :ref="`multiSelect+${$index}`"
                size="small"
                @change="changeGoods($event,$index)" value-key="id">
                <el-option v-for="item in goodArr" :label="item.good_name" :value="item" :key="item.id">
                  <div>
                    <span style="float: left">{{ item.good_name }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.name }}</span>
                  </div>
                  <div style="color:#999;margin-top: -5px;" v-if="!!item.norm_name">标名：{{item.norm_name}}</div>
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            prop="order_cate_name"
            label="下单分类">
            <template slot-scope="{row}">
              <span>{{row.order_cate_name}}</span>
              <span v-if="!!row.order_tow_name"> / {{row.order_tow_name}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="supplier_name"
            label="供应商/采购员"
            width="120px">
            <template slot-scope="{row,$index}">
              <el-popover
                :ref="`popoverDom+${$index}`"
                placement="bottom"
                width="300"
                trigger="click">
                <div>供应商/采购员</div>
                <el-select v-model="person_obj" filterable style="width:100%;margin:10px 0;" @focus="getSupplier" value-key="id">
                  <el-option v-for="item in supplierArr" :label="item.name" :value="item" :key="item.id">
                    <div>
                      <span style="float: left">{{ item.name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.type == 1 ? '供应商' : '采购员' }}</span>
                    </div>
                  </el-option>
                </el-select>
                <el-row type="flex" justify="center">
                  <el-button type="primary" @click="handleRemark(row,$index)">确定</el-button>
                </el-row>
                <el-button slot="reference" type="text"  style="color:#409eff;width:98px;text-align: left;">
                  <span v-if="!!row.supplier_name" style="width:98px;display: inline-block;overflow: hidden;">{{row.supplier_name}}</span>
                  <i v-else class="el-icon-edit"></i>
                </el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            prop="unit_name"
            label="单位">
          </el-table-column>
          <el-table-column
            prop="name"
            label="规格">
            <template slot-scope="{row}">{{row.name}}</template>
          </el-table-column>
          <el-table-column 
            prop="purchase_price"
            label="最近一次采购价">
          </el-table-column>
          <el-table-column
            prop="num"
            label="*下单数量"
            width="100px">
            <template slot-scope="{row,$index}">
              <el-input v-model="row.num" placeholder="请输入" size="small" @input="numREG_EXP(row,$index)"  ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            width="180px">
            <template slot-scope="{row}">
              <el-input v-model="row.remark" autosize type="textarea" placeholder="请输入（限50字）" size="small" maxlength="50"></el-input>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footerWrap">
        <el-button size="medium" @click="handleReturn">取消</el-button>
        <el-button type="primary" size="medium" :loading="form.loading" @click="confirm">保存</el-button>
      </div>
    </div>

    <!-- 批量选择商品弹窗 -->
    <BatchGoods ref="batchGoods" :area="form.data.area" @sendgoodslist="harvestgoodslist" v-if="isShowDialog"></BatchGoods>
  
  </div>
</template>

<script>
  import BatchGoods from "@/layout/BatchGoods"
  import { mixinTable } from "@/mixins/table.js";
  import { getStorage } from '@/storage'
  export default {
    name: 'purchaseCreated',
    mixins: [mixinTable],
    components: {
      BatchGoods
    },
    data() {
      return {
        token: getStorage('token'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        isCopy: false,
        table:{
          params: {
            page:1,
            count: 10,
          },
          total: 0,
          data:[
          ],
        },
        tableData: [
          {id:'',name:''}
        ],
        specArr:[],
        loading: false,
        form: {
          loading: false,
          data: {
            delivery_date:'',
            stash_id: '',
            area:[15,244], // 默认江西南昌
          },
          rules: {
            delivery_date: [{ required: true, message:'请选择', trigger: 'change'}],
            stash_id:[{ required: true, message:'请选择', trigger: 'change'}],
            area:[{ required: true, message:'请选择', trigger: 'change'}],
          },
        },
        fileList: [],
        areaArr: [],
        isShowDialog: false,
        totalNum: 0,
        goodArr: [], // 商品列表
        stashArr: [], // 仓库
        pickerOptionsDate: {
          disabledDate(time) {
            return time.getTime() < Date.now() - 8.64e7; // 禁用历史日期
          },
        },
        specIds: '',
        selLoading: false,
        supplierArr: [], // 供应商/采购员
        person_obj: {}, // 供应商/采购员参数
        
      }
    },
    created() {
      this.form.data.delivery_date = this.$moment(new Date().getTime() + 60 * 60 * 24 * 1000).format("YYYY-MM-DD") // 默认明天
    },
    methods: {
      getDetail(row) {
        this.getArea();
        this.getStash(); // 仓库
        if(!!row.id) { // 有id就是复制
        console.log("---复制")
          this.isCopy = true;
          this.isChange = true;
          let _params = {
            id: row.id,
            page:1,
            count:1000
          }
          this.$http.get('/admin/purchase_order/info', { params: _params}).then(res => {
            if(res.code == 1) {
              this.form.data.delivery_date = res.data.main.delivery_date;
              this.form.data.area = [res.data.main.province, res.data.main.city];
              this.form.data.stash_id = res.data.main.stash_id == 0 ? '' : res.data.main.stash_id;

              if(res.data.details.list.length > 0) {
                // 要复制的商品信息
                var arrList = res.data.details.list.filter(function(item) {
                  return  delete item.id
                });
                this.specIds = res.data.details.list.map(item => {
                  return item.spec_id
                }).join("|")

                this.$http.get('/admin/goods/specList', { params:{ page: 1, count: 10000, status:1,ids: this.specIds}}).then(res => {
                  if(res.code == 1) {
                    if(res.data.list.length == arrList.length) {
                      // // 根据规格id相同合并复制商品的下单数量和备注
                      // this.tableData = res.data.list.map(item1 => {
                      //   const item2 = arrList.find(item2 => item2.spec_id === item1.id)
                      //   return item2 ? {...item1, remark:item2.remark, num:item2.num } : item1
                      // });

                      // 根据规格id相同，合并复制商品数据
                      this.tableData = arrList.map(item1 => {
                        const item2 = res.data.list.find(item2 => item2.id === item1.spec_id)
                        return item2 ? {
                          ...item1,
                          good_name: item1.goods_name, // 商品名称
                          id: item1.spec_id, // 规格id
                          name: item1.spec_name, // 规格名称
                          num: item1.num, // 下单数量
                          purchase_price: item2.purchase_price, // 最后一次采购价
                          supplier_id: item1.bind_id, // 供应商/采购员
                          remark: '', // 复制时清空备注
                        } : {...item1,supplier_id: item1.bind_id,remark: ''}
                      });
                      this.$set(this.tableData)
                    } else {
                      this.tableData = arrList.map(item => {
                        return {
                          id: item.spec_id, // 规格id
                          image: item.goods_image,
                          good_name: item.goods_name,
                          name: item.spec_name,
                          purchase_price: item.last_purchase_price, // 最后一次采购价
                          ...item,
                          supplier_id: item.bind_id,// 供应商/采购员
                          remark: '', // 复制时清空备注
                        }
                      })
                    }
                  }
                })
              }
            }
          })
        }
      },
      // 获取全国省市区
      getArea() {
        this.$http.post('/common/district/all', {is_all:1}).then(res => {
          if(res.code === 1) {
            this.areaArr = res.data.list;
            for (const key in this.areaArr) {
              // 删除第三层children属性
              for (const i in this.areaArr[key].children) {
                delete this.areaArr[key].children[i].children
              }
            }
          }
        })
      },
      // 获取仓库
      getStash() {
        this.$http.get('/admin/stash/list', { params:{ page: 1, count: 10000}}).then(res => {
          if(res.code == 1) {
            this.stashArr = res.data.list;
            if(res.data.list.length == 1) {
              this.form.data.stash_id = res.data.list[0].id
            }
          }
        })
      },
      // 获取供应商/采购员(status:1启用中的供应商,下单地区内的供应商采购员)
      getSupplier() {
        if(this.form.data.area.length < 1) {
          this.$message.warning('请先选择下单地区')
          return
        }
        let _params = {
          province: this.form.data.area[0],
          city: this.form.data.area[1],
          status:1,
          page:1, 
          count:10000
        }
        this.$http.get('/admin/supplier/allList',{params:_params}).then(res => {
          if(res.code == 1) {
            this.supplierArr = res.data.list;
          }
        })
      },
      reset(done) {
        this.isChange = false;
        this.isCopy = false;
        this.$refs.elFormDom.resetFields()
        this.tableData = [];
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 减
      onMinus(row,index) {
        console.log(row,index)
        if(this.tableData.length > 1) {
          this.tableData.forEach((v,i) => {
            if(index == i) {
              this.tableData.splice(i, 1)
            }
          })
          this.specArr = this.specArr.filter(j => j != row.id);
        } else {
          this.$message.warning('最后一条数据了!')
        }
      },
      // 加
      onAdd(row,index) {
        let str = {
          image: require('@/assets/img/default.jpg'),
          good_name:'',
          num:'',
          remark:'',
        }
        this.tableData.splice(index+1,0,str); // 添加到指定位置
        this.$set(this.tableData,row,index)
        this.$nextTick(() => {
          this.$refs[`multiSelect+${index + 1}`].focus()
        })
        this.goodArr = []; //清除上次的查询商品数据
      },
      // 统计下单数量
      countNum(arr) {
        let sum = 0;
        arr.map(function (item) {
          sum += Number(item.num)
        })
        return sum.toFixed(2) * 1
      },
      // 保存
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let currArr = this.tableData.filter(j => !!j.id) // 过滤掉列表中未选商品的空的数据
            if(currArr.length == 0) {
              this.$message.warning('请先添加商品')
              return
            }
            // 计算下单数量合计
            let num = this.countNum(currArr)
            let _spec = currArr.map(v => {
              return {
                spec_id: v.id,
                num: v.num,
                remark: v.remark || '',
                bind_id: v.supplier_id, // 供应商/采购员
              }
            })
            // 验证数量必填
            let is_num = _spec.some((item) => !item.num);
            if(is_num) {
              this.$message.warning("请输入商品下单数量！")
              return
            }
            console.log(_spec)
            let _params = {
              delivery_date: this.form.data.delivery_date,
              province: this.form.data.area[0],
              city: this.form.data.area[1],
              stash_id: this.form.data.stash_id,
              spec: _spec
            }
            console.log(_params)

            // return
            this.$confirm(`采购商品项数：${currArr.length},   数量合计：${num}`, '确定下单吗', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              center: true
            }).then(() => {
              this.form.loading = true;
              this.$http.post('/admin/purchase_order/add', _params).then(res => {
                if(res.code == 1) {
                  this.toggle(false)
                  this.$emit("refresh")
                  this.$message.success('操作成功！')
                }
              }).finally(() => {
                this.form.loading = false;
              })
            }).catch(() => {});
          }
        })
      },
      // 图片上传成功
      handleAvatarSuccess(event) {
        this.loading = false;
        if (event.code == 200) {
          this.form.data.file_name = event.data.path[0];
        } else {
          this.$message({
            type: "error",
            message: event.msg,
          });
        }
      },
      // 移除图片
      handleRemove(file, fileList) { 
        this.form.data.file_name = "";
        this.fileList = [];
      },
      // 文件上传时的钩子
      handleUpload() {
        this.loading = true;
      },
      // 批量添加弹窗
      batchAdd() {
        console.log(this.form.data.area)
        if(this.form.data.area.length < 1) {
          this.$message.warning("请先选择下单地区")
          return
        }
        this.isShowDialog = true
      },
      // 接受父组件传过来的商品列表
      harvestgoodslist(val) {
        let newSpecid = []
        newSpecid = this.tableData.map(v => { return v.id })
        this.specArr = newSpecid;
        val.forEach(item => {
          if(this.specArr.indexOf(item.id) >= 0){
            this.tableData.map(value => {
              if(value.id == item.id){
                value.num = (Number(value.num) + Number(item.num)).toFixed(1) * 1;
                value.price_curr = Math.round(parseFloat(value.price * value.num)*100)/100;
              }
            });
          }else{
            this.tableData.push({
              ...item,
              price_curr: Math.round(parseFloat(item.price * item.num)*100)/100,
            });
            this.specArr.push(item.id);
          }
        });
        //this.tableData = this.tableData.concat(arr)
        console.log(this.tableData)
        this.isShowDialog = false;
      },

      // 选择商品
      changeGoods(value, index) {
        let newSpecid = []
        newSpecid = this.tableData.map(v => { return v.id })
        this.specArr = newSpecid;
        if(this.specArr.indexOf(value.id) >= 0) {
          this.$message.warning("已经添加过该规格");
          this.tableData[index].good_name = {}; // 当前行搜索值清空
          this.goodArr = []; // 当前行筛选数据清空
          return; 
        }
        this.specArr.push(value.id);
        let _currRow = {...value,num: ''};
        this.tableData[index] = _currRow;
        this.$set(this.tableData, index, {..._currRow})
      },
      proving(e) {
        this.form.data[e].num = this.form.data[e].num.match(/[^\d.]/g,"")
      },
      // 返回
      handleReturn() {
        this.$emit("refresh")
        this.toggle(false)
        this.tableData = [
          {id:'',name:''}
        ]
      },
      numREG_EXP(row,index) {
        const reg = /^[\d]+$/;
        // 下单数量单位只有是（克、两、斤、千克）的时候可以输小数点一位小数，其他的请控制不允许输小数点
        if(row.unit_name == '克' || row.unit_name == '两' || row.unit_name == '斤' || row.unit_name == '千克') {
          if (row.num && !reg.test(row.num)) {
            row.num = row.num=row.num.match(/\d+(\.\d{0,1})?/) ? row.num.match(/\d+(\.\d{0,1})?/)[0] : '';
          }
        } else{
          if (row.num && !reg.test(row.num)) {
            row.num = row.num.replace(/[^\d]/g, '');
          }
        }
        if(!!row.num) {
          this.$set(this.tableData, index, {...row,num:row.num})
        }
      },
      remoteMethod(query) {
        if(query !== '') {
          if(this.form.data.area.length < 1) {
            this.$message.warning('请先选择下单地区')
            return
          }
          this.selLoading = true;
          let _params = {
            province: this.form.data.area[0],
            city: this.form.data.area[1],
            page: 1,
            count: 1000,
            status:1,
            good_name: query
          }
          this.$http.get('/admin/goods/specList', { params:_params}).then(res => {
            if(res.code === 1) {
              this.goodArr = res.data.list;
            }
          }).finally(() => {
            this.selLoading = false;
          })
        } else {
          this.goodArr = [];
        }
      },
      changeOrder(value) {
        this.form.data.area = [value.province,value.city]
      },
      // 修改供应商/采购员
      handleRemark(row,index) {
        if(!this.person_obj.id) {
          this.$message.warning("请选择供应商/采购员")
          return
        }
        this.$refs[`popoverDom+${index}`].doClose()
        this.tableData[index].supplier_id = this.person_obj.id;
        this.tableData[index].supplier_name = this.person_obj.name;
        document.body.click();
        this.person_obj = {};
      },
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
} 
.footerWrap {
  text-align: center;
  padding: 10px;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
  z-index: 99;
}
.itemTime {
  display: block;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.flex {
  display: flex;
}
.mt10 {
  margin-top: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.width200px {
  width: 200px;
}
.after-table {
  padding: 10px;
  font-size: 13px;
  color: #303030;
  text-align: right;
}
.el-select-dropdown__item {
  display: flex;
  flex-direction: column;
  height: auto !important;
}
</style>



