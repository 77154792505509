<template>
  <!-- 收货 -->
  <div class="addPerson__wrap" v-show="visible">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="handleReturn">
          <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
          <span>返回</span>
        </div>
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.title }}
          <span> > </span>
          收货
        </div>
      </div>
    </el-header>
    <div class="main">
      <div class="mainBox">
        <div class="itemTime">基础信息</div>
        <el-descriptions title="" >
          <el-descriptions-item label="采购来源">
            <span>{{info.source == 1 ? '销售订单' : '平台自采'}}</span>
          </el-descriptions-item>
          <el-descriptions-item label="计划交货日期">{{info.delivery_date}}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{info.create_time}}</el-descriptions-item>
          <el-descriptions-item label="采购单号">{{info.order_no}}</el-descriptions-item>
          <el-descriptions-item label="采购状态">{{ toStr({0:'待发货',1:'待收货', 2:'已取消', 3:'部分收货', 4:'全部收货'}, info.status)}}</el-descriptions-item>
          <el-descriptions-item label="地区">{{info.city_name}}</el-descriptions-item>
          <el-descriptions-item label="仓库名称" v-if="!!info.stash_name">{{info.stash_name}}（{{info.stash_province}}{{info.stash_city}}）</el-descriptions-item>
          <el-descriptions-item label="供应商/采购员" v-if="!!info.supplier_name">{{info.supplier_name}}</el-descriptions-item>
          <el-descriptions-item label="已收货数量" v-if="!!info.delivery_num && info.delivery_num > 0">{{info.delivery_num}}</el-descriptions-item>
          <el-descriptions-item label="已收货金额" v-if="!!info.delivery_money && info.delivery_money > 0">￥{{info.delivery_money}}</el-descriptions-item>
        </el-descriptions>

        <div class="itemTime">收货 <span style="color:#999;font-size:14px;">(共{{table.total}}项)</span></div>
        <el-input
          style="width:240px;margin-bottom:10px;"
          v-model.trim="searchForm.data.goods_name"
          placeholder="请输入商品名称"
          size="small"
          clearable
          @input="searchForm.data.goods_name=searchForm.data.goods_name.replace(/^-?\d+\.?\d*$/,'')"
          @keydown.enter.native="getTable"
          @clear="getTable"
        >
        </el-input>
        <!-- is_sorter:1：是， 若供应商是支持代分拣的收货登记增加了食堂名称字段 -->
        <el-select 
          v-if="info.is_sorter == 1"
          v-model="searchForm.data.address_id" 
          filterable placeholder="请输入食堂名称" 
          style="width:240px;margin-bottom:10px;margin:0 10px;"
          clearable 
          size="small"
          @focus="getStore" 
          @change="getTable">
          <el-option v-for="item in storeArr" :key="item.id" :label="item.name" :value="item.id">
            <div>
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">({{ item.merchant_name }})</span>
            </div>
          </el-option>
        </el-select>
        <el-button type="primary" size="small" style="margin-left:10px;" @click="getTable">查询</el-button>
        <el-button plain type="primary" size="small" style="float: right;" @click="handleBill">上传单据</el-button>
        
        <el-table
          :data="tableData"
          style="width: 100%"
          max-height="450"
          border
          :cell-style="{background:'#fff'}"
          :span-method="objectSpanMethod"
          @selection-change="handleSelectionChange">
          <el-table-column
            width="80"
            fixed
            v-if="info.status == 0 || info.status == 1"
            >
            <!-- 采购状态待发货或待收货时加编辑操作 -->
            <template slot="header" slot-scope="{}">
              <i class="el-icon-s-fold"></i>
            </template>
            <template slot-scope="{row, $index}">
              <div style="display:flex;align-items:center;">
                <el-popconfirm title="确认删除此商品？" @confirm="onMinus(row,$index)">
                  <el-button slot="reference" plain style="width: 22px;height: 18px;padding: 0;">-</el-button>
                </el-popconfirm>
                <el-button plain style="margin-top:1px;margin-left:5px;display:block;width: 22px;height: 18px;padding: 0;" @click="onAdd(row,$index)">+</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            type="selection"
            width="55"
            :selectable="selectEnable" fixed>
          </el-table-column>
          <el-table-column
            prop="good_img"
            label="商品名称"
            width="180px"
            fixed
            key="1"
            >
            <template slot-scope="{row,$index}">
              <div style="display: flex;align-items: center;" v-if="!!row.id">
                <el-image
                  v-if="!!row.image"
                  style="width: 40px; height: 40px"
                  :src="row.image"
                  fit="cover"
                  :preview-src-list="[row.image]"></el-image>
                <el-image
                  v-else
                  style="width: 40px; height: 30px"
                  :src="require('@/assets/img/logo-icon.jpeg')"
                  fit="cover"
                  :preview-src-list="[require('@/assets/img/logo-icon.jpeg')]"></el-image>
                <span style="margin-left:10px;">{{row.good_name}}</span>
              </div>
              <template v-else>
                <el-select
                  v-model="row.good_name"
                  filterable
                  remote
                  placeholder="请输入关键词"
                  :remote-method="remoteMethod"
                  :loading="selLoading"
                  :ref="`multiSelect+${$index}`"
                  size="small"
                  @change="changeGoods($event,$index)" value-key="id">
                  <el-option v-for="item in goodArr" :label="item.good_name" :value="item" :key="item.id">
                    <div>
                      <span style="float: left">{{ item.good_name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.name }}</span>
                    </div>
                    <div style="color:#999;margin-top: -5px;" v-if="!!item.norm_name">标名：{{item.norm_name}}</div>
                  </el-option>
                </el-select>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="code"
            label="商品编码"
            width="120px"
            key="2">
          </el-table-column>
          <el-table-column
            prop="order_cate_name"
            label="下单分类"
            key="3"
            min-width="120px">
            <template slot-scope="{row}">
              <span>{{row.order_cate_name}}</span>
              <span v-if="!!row.order_tow_name"> / {{row.order_tow_name}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="unit_name"
            label="单位"
            width="80"
            key="4">
          </el-table-column>
          <el-table-column
            prop="name"
            label="规格"
            key="5">
          </el-table-column>
          <el-table-column
            prop="last_purchase_price"
            label="采购价"
            width="100px"
            key="6">
            <template slot-scope="{row}">
              {{row.last_purchase_price}}
              <div v-if="row.extent_curr != 0 && row.old_purchase_price != 0" style="display:inline;">
                <i :class="row.extent_curr > 0 ? 'el-icon-top redColor' : 'el-icon-bottom greenColor'"></i>
                <span :class="row.extent_curr > 0 ? 'extent redColor' : 'extent greenColor'">{{Math.abs(row.extent_curr)}}</span>
              </div>
            </template>
          </el-table-column>
          <!-- 不支持分拣的供应商数据，不需要显示食堂名称字段 -->
          <el-table-column
            v-if="info.is_sorter == 1"
            prop="store_name"
            label="*食堂名称"
            width="140"
            key="7">
            <template slot-scope="{row}">
              <div v-if="!!row.store_name">{{row.store_name}}</div>
              <div v-else>
                <el-select v-model="row.address_id" filterable @focus="getStore" size="mini" @change="actionFun(row)">
                  <el-option v-for="item in storeArr" :key="item.id" :label="item.name" :value="item.id">
                    <div>
                      <span style="float: left">{{ item.name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">({{ item.merchant_name }})</span>
                    </div>
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="采购状态"
            key="8">
            <template slot-scope="{row}">{{toStr({1:'待收货', 2:'取消', 3:'删除', 4:'部分收货',5:'全部收货'},row.status)}}</template>
          </el-table-column>
          <el-table-column
            prop="num"
            label="下单数量"
            width="100px"
            key="9">
            <template slot-scope="{row,$index}">
              <!-- 采购状态待发货或待收货时可编辑下单数量 -->
              <el-input v-if="info.status == 0 || info.status == 1" v-model="row.num" placeholder="请输入" size="small" @blur="numREG_EXP(row,$index)"></el-input>
              <span v-else>{{row.num}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="delivery_num"
            label="已收货数量"
            width="100px"
            key="10">
          </el-table-column>
          <el-table-column
            prop="delivery_num_curr"
            label="本次收货数量"
            width="110px"
            key="11">
            <template slot-scope="{row,$index}">
              <span v-if="row.status == 5">0</span>
              <el-input v-else v-model="row.delivery_num_curr" placeholder="请输入" size="small" @input="countPrice(row,$index)"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="cov_unit"
            label="采购单位"
            width="140"
            key="12">
            <template slot-scope="{row,$index}">
              <!-- 收货完成的采购单位不可以修改 -->
              <span v-if="row.status == 5" class="greenColor">{{row.cov_unit}}</span>
              <el-button v-else icon="el-icon-edit" type="text" @click="openPopover(row,$index)">{{row.cov_unit}}</el-button>
              <span v-if="row.cov_times != 0">（{{row.cov_times}}{{row.unit_name}}）</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="delivery_money_curr"
            label="本次收货单价"
            width="110px"
            key="13">
            <template slot-scope="{row,$index}">
              <span v-if="row.status == 5">0</span>
              <el-input v-else v-model="row.delivery_money_curr" placeholder="请输入" size="small" @input="countPrice(row,$index)"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="price"
            label="本次收货金额"
            width="120px"
            key="14">
            <template slot-scope="{row,$index}">
              <span v-if="row.status == 5">0</span>
              <el-input v-else v-model="row.total_money_curr" placeholder="请输入" size="small" @input="totalPrice(row,$index)"></el-input>
            </template>
          </el-table-column>
          <!-- 采购状态待发货或待收货时可编辑备注 -->
          <template v-if="info.status == 0 || info.status == 1">
            <el-table-column
              prop="remarks"
              label="备注"
              min-width="180px"
              key="15">
              <template slot-scope="{row}">
                <div v-for="(v,i) in row.remarks" :key="i">
                  <el-input v-model="v.context" placeholder="请输入（限50字）" size="small" type="textarea" maxlength="50" @blur="actionFun(row)"></el-input>
                </div>
              </template>
            </el-table-column>
          </template>
          <el-table-column
            prop="action"
            label="操作"
            width="150px"
            fixed="right"
            key="16"
            >
            <template slot-scope="{row}">
              <!-- 待收货或部分收货，才有收货操作-->
              <template v-if="row.status == 1 || row.status == 4">
                <el-button type="text" slot="reference" @click="handeReceivePart(row)">部分收货</el-button>
                <el-button type="text" slot="reference" @click="handeReceiveAll(row)">全部收货</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <!-- 收货完成后不展示该信息 -->
        <div v-if="info.status != 4" style="text-align:right;font-size: 14px;margin-top: 10px;color:#777;">
          <span>本次收货数量：<span style="font-weight:bold;color:#f56c6c;font-size:16px;">{{totalNumAll}}</span></span>
          <span style="margin-left:10px;">本次收货金额：<span style="font-weight:bold;color:#f56c6c;font-size:16px;">￥{{totalMoneyAll}}</span></span>
        </div>

        <!-- 有上传采购单据则展示出来 -->
        <template v-if="!!info.bills && info.bills.length > 0">
          <div class="itemTime">采购单据</div>
          <div class="mt20" v-for="(item,index) in info.bills" :key="index">
            <div>
              <span class="name">{{item.name}} </span>
              <span class="time">{{item.create_time}} </span>
            </div>
             <el-image
              v-for="img in item.bills" :key="img"
              style="width: 60px; height: 60px;margin-top:10px;margin-right:10px;"
              :src="img" 
              :preview-src-list="item.bills">
            </el-image>
          </div>
        </template>
      </div>
      <!-- status //状态 1:待收货 2:已关闭（已取消） 3:部分收货 4:全部收货（完成） -->
      <div class="footerWrap" v-if="info.status != 4">
        <el-button size="medium" @click="handleBatch">部分收货</el-button>
        <el-button type="primary" size="medium" @click="confirm">收货完成</el-button>
        <!-- <el-button type="warning" size="medium" v-if="info.status == 0 || info.status == 1" @click="handleEditInfo">保存编辑信息</el-button> -->
      </div>
    </div>

    <el-dialog
      title="选择仓库"
      :visible.sync="dialogVisible"
      width="400px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      center>
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="选择仓库" prop="stash_id">
          <el-select v-model="form.data.stash_id" @focus="getStash">
            <el-option v-for="item in stashArr" :label="item.name" :value="item.id" :key="item.id">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.province_str}}{{item.city_str}}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-row type="flex" justify="center">
          <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click="handeReceiveAllFun">确定</el-button>
        </el-row>
      </template>
    </el-dialog>

    <!-- 修改采购单位 -->
    <el-dialog
      title=""
      :visible.sync="dialogVisibleUnit"
      width="400px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      center>
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDomUnit'
        label-width='60px'
        label-position='right'
        :model='formUnit.data'
        :rules='formUnit.rules'
        :size="subUnitSize"
      >
        <el-form-item label="单位">
          <el-select v-model="formUnit.data.cov_unit" size="mini" filterable @focus="getUnit">
            <el-option v-for="item in unitArr" :label="item.name" :value="item.name" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="=" prop="cov_times" v-if="formUnit.data.cov_unit != currGoodsRow.unit_name">
          <el-input v-model="formUnit.data.cov_times" placeholder="请输入" size="small" @input="formUnit.data.cov_times=formUnit.data.cov_times.match(/\d+(\.\d{0,2})?/) ? formUnit.data.cov_times.match(/\d+(\.\d{0,2})?/)[0] : ''">
            <span slot="suffix">{{currGoodsRow.unit_name}}</span>
          </el-input>
        </el-form-item>
      </el-form>
      <!-- 采购单位和商品单位不一致才可以点击修改按钮 -->
      <template #footer v-if="formUnit.data.cov_unit != currGoodsRow.unit_name">
        <el-row type="flex" justify="center" >
          <el-button type="primary" @click="handleUnit">确定</el-button>
        </el-row>
      </template>
    </el-dialog>

    <!-- 上传单据 -->
    <el-dialog
      title=""
      :visible.sync="dialogVisibleBill"
      width="400px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      center>
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDomBill'
        label-width='110px'
        label-position='top'
        :model='formBill.data'
        :rules='formBill.rules'
        :size="subUnitSize"
        max-height="600px"
      >
        <el-form-item label="上传单据(最多6张)" prop="bills">
          <el-upload
            action="/api/common/qiniu/upload"
            :limit="6"
            list-type="picture-card"
            class="imgWrap"
            ref="upload"
            multiple
            :headers="{ token: token }"
            :on-preview="handlePictureCardPreview"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :on-remove="handleRemove2"
            :file-list="fileList"
            :on-exceed="handleExceed"
            :http-request="uploadFile"
            accept="image/jpeg, image/png, image/gif"
            :auto-upload="true"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisibleUrl" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt />
          </el-dialog>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-row type="flex" justify="center">
          <el-button type="primary" :loading="loadingBill" @click="sumbitBill">确定</el-button>
        </el-row>
      </template>
    </el-dialog>

  </div>
</template>

<script>
  import { mixinTable } from "@/mixins/table.js";
  export default {
    name: 'purchaseReceive',
    mixins: [mixinTable],
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        info: {
          bills:[],
          is_sorter: '', // 该供应商是否支持代分拣 1：是，2否
        },
        is_bills: false, // 是否上传了采购单据
        currIndex: 0,
        currRow: {
          order:{}
        },
        backupTableData: [], // 备份一份最初数据
        tableData: [
          {
            good_img: require('@/assets/img/lb.png'),
            good_name:'小白菜',
            num:'',
            remarks: [{context:''}], // 备注
            id:0,
            extent_curr:0,
          }
        ],
        table: {
          total:0,
        },
        
        loading: false,
        searchForm: {
          loading: false,
          data: {
            goods_name: '',
            supplier_name: '', //供应商、采购员
            address_id: '', //食堂
          },
        },
        multipleSelection: [],
        stashArr: [],
        dialogVisible: false,
        form: {
          loading: false,
          data: {
            stash_id: '', // 仓库id
          },
          rules:{
            stash_id: [{ required: true, message:'请选择', trigger: 'change'}],
          }
        },
        formUnit: {
          loading: false,
          data: {
            cov_unit: '', // 采购单位
            cov_times: '', // 采购单位对应数量
          },
          rules:{
            cov_times: [{ required: true, message:'必填项', trigger: 'change'}],
          }
        },
        formBill: {
          loading: false,
          data: {
            bind_id: '',// 供应商/采购员
            bills:'', //单据
          },
          rules:{
            type: [{ required: true, message:'请选择', trigger: 'change'}],
            bind_id: [{ required: true, message:'请选择', trigger: 'change'}],
            bills: [{ required: true, message:'请上传', trigger: 'change'}],
          }
        },
        currGoodsRow: {}, // 当前操作行
        receiveType: '', // 1部分收货，2全部收货
        unitArr: [],// 计件单位
        dialogVisibleUnit: false, // 采购单位弹窗
        dialogVisibleBill: false, // 上传单据
        loadingBill: false,
        storeArr: [], // 食堂
        
        fileList: [],
        dialogImageUrl: '', 
        dialogVisibleUrl: false,

        pos:0,
        spanArr: [],// 合并单元格
        totalNumAll: 0, // 本次收货数量总计
        totalMoneyAll: 0, // 本次收货金额总计
        imgFileList:[],
        selLoading: false,
        goodArr: []
      }
    },
    methods: {
      async getDetail(row) {
        this.currRow = row;
        await this.getTable();
      },
      getTable() {
        let _params = {
          id: this.currRow.id,
          goods_name: this.searchForm.data.goods_name,
          supplier_name: this.searchForm.data.supplier_name,
          address_id: this.searchForm.data.address_id,
          page: 1,
          count: 1000
        }
        this.$http.get('/admin/purchase_order/info', { params: _params}).then(res => {
          if(res.code == 1) {
            // 保存没有选项条件时的商品数据
            if(this.searchForm.data.goods_name == '' && this.searchForm.data.address_id == '') {
              this.backupTableData = res.data.details.list;
            }
            this.form.data.area = [res.data.main.province, res.data.main.city];
            this.info = res.data.main;
            this.is_bills = res.data.main.bills.length > 0 ? true : false; //是否上传单据全部收货的时候用
            this.tableData = res.data.details.list.map(v => {
              let _delivery_num_curr =  Number(v.num - v.delivery_num) > 0 ? Number(v.num - v.delivery_num) : 0; // 本次收货数量 (如果减出负数就默认为0) = 下单数量 - 已收货数量
              if(v.is_send > 0 && v.status == 1) _delivery_num_curr = v.sorter_num; // 本次收货数量，is_send > 0采购状态是待收货就取分拣数量 
              return {
                delivery_num_curr: _delivery_num_curr, //本次收货数量
                extent_curr: Math.round(v.last_purchase_price * 100 - v.old_purchase_price *100) / 100 , // 计算采购价上升或下降
                delivery_money_curr: v.last_purchase_price, // 本次收货价格
                total_money_curr: (_delivery_num_curr * v.last_purchase_price).toFixed(2) * 1, // 本次收货金额（小计）= 本次收货数量 * 本次收货价格
                ...v,
                bind_id: v.bind_id == 0 ? '' : v.bind_id, // 供应商/采购员
                cov_unit: !!v.cov_unit ? v.cov_unit : v.unit_name, // 采购单位

                name: v.spec_name, // 规格名称
                image: v.goods_image, // 商品图片
                good_name: v.goods_name,// 商品名称
                address_id: v.address_id == 0 ? '' : v.address_id, // 食堂id
              }
            })

            // 计算本次收货数量总计和本次收货金额总计
            this.totalNumAll = this.countNum(this.tableData);
            this.totalMoneyAll = this.countMoney(this.tableData);

            // 支持分拣供应商且来源为销售订单的商品去重
            if(this.info.is_sorter == 1 && this.info.source == 1) {
              let some = [];
              this.tableData.forEach(el => {
                if (!some.some(e => (e.code == el.code && e.spec_name == el.spec_name))) {
                  some.push(el);
                }
              });
              this.table.total = some.length;
            } else {
              this.table.total = res.data.details.total;
            }
            
            this.pos = 0; // 初始化合并单元格数据
            this.spanArr = []; // 初始化合并单元格数据
            this.getSpanArr(this.tableData)
          }
        })
      },
      // 获取食堂
      getStore() {
        this.$http.get('/admin/path/storeList', {params: {page:1,count:10000}}).then(res => {
          if(res.code === 1) {
            this.storeArr = res.data.list;
          }
        })
      },
      // 获取启用中的仓库
      getStash() {
        // status 状态 1:启用 2:禁用
        this.$http.get('/admin/stash/list', { params:{ page: 1, count: 10000, status:1}}).then(res => {
          if(res.code == 1) {
            this.stashArr = res.data.list;
          }
        })
      },
      reset(done) {
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 所有商品全部完成收货, （2024/3/13 改成批量完成收货）
      async confirm() {
        // 待发货或待收货状态可编辑时先调编辑保存接口
        if(this.info.status == 0 || this.info.status == 1) {
          await this.handleEditInfo();
        }
        // 1:'待收货', 2:'取消', 3:'删除', 4:'部分收货',5:'全部收货'
        if(this.multipleSelection.length > 0) {
          //则最后一条数据收货需要上传单据 (未上传单据不可以全部收货)
          let _list = this.tableData.filter(v => v.status != 5 ) // 先过滤掉收货完成的商品
          let count = _list.length - this.multipleSelection.length; // 列表数据 -批量选择全部收货的数据 = 剩下待收货数据
          if(count == 0 && this.is_bills == false) { 
            this.$message.warning("未上传采购单据，请先上传单据")
            return
          }

          var arr = this.multipleSelection;
          let num = this.countNum(arr) // 计算收货总数量
          let money = this.countMoney(arr); // 计算收货总金额
          let is_money = arr.some((item) => !item.delivery_money_curr); // 检测提交的数据是否填了数量或金额
          // let is_num = arr.some((item) => !item.delivery_num_curr); // 检测提交的数据是否填了数量或金额
          // console.log(is_money, "is_money")
          // console.log(is_num, "is_num")
          if(is_money == true) {
            this.$message.warning("请输入商品规格收货金额")
            return
          }
          // if(is_num == true) {
          //   this.$message.warning("请输入商品规格收货数量")
          //   return
          // }
          this.$confirm(`已收货${arr.length}个商品，数量${num}，金额${money}元`, '确定商品已经全部收货完成了吗', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            center: true
          }).then(() => {
            this.receiveType = 2;
            if(this.info.stash_id == 0) { //若没有仓库先选仓库
              this.dialogVisible = true;
            } else {
              this.allReceiveFun()
            }
          }).catch(() => {});
        } else {
          this.$message.warning("请至少选择一条数据")
        }
      },

      // 所有商品全部完成收货方法 （批量全部收货方法）
      allReceiveFun() {
        let _spec = this.multipleSelection.map(v => {
          return {
            id: v.id, //明细ID
            delivery_num: !!v.delivery_num_curr ? v.delivery_num_curr : 0, //本次收货数量
            delivery_money: v.delivery_money_curr, //本次收货价格
            total_money: v.total_money_curr, // 本次收货小计
          }
        }) 
        let _params = {
          id: this.currRow.id, // 订单id
          stash_id: this.form.data.stash_id, // 仓库id
          type: 2, //批量全部收货
          spec: _spec
        }
        this.$http.post('/admin/purchase_order/receive', _params).then(res => {
          if(res.code == 1) {
            this.dialogVisible = false;
            this.getTable();
            this.$message.success('操作成功！')
          }
        })
      },

      // 单条部分收货
      async handeReceivePart(row) {
        // 待发货或待收货状态可编辑时先调编辑保存接口
        if(this.info.status == 0 || this.info.status == 1) {
          await this.handleEditInfo();
        }
        if(!row.delivery_money_curr) {
          this.$message.warning("请先输入本次收货金额！")
          return
        }
        // 部分收货状态且已部分收货的商品，收货数量允许填负数且（本次收货数量 + 已收货数量不能小于0）
        if(row.status == 4 && (Number(row.delivery_num_curr) + Number(row.delivery_num) ) < 0) {
          this.$message.warning("单个商品累计收货数量不能小于0")
          return 
        }
        this.$confirm('请确认收货操作', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.currGoodsRow = row;
          this.receiveType = 1; // 1:单条部分收货，
          if(this.info.stash_id == 0){ //如果没有仓库要先选仓库
            this.dialogVisible = true;
          } else {
            let data = {
              id: this.currRow.id,
              type: this.receiveType,  //类型 1:单条部分收货 2:单条全部收货
              stash_id: this.form.data.stash_id || '',
              spec: [
                {
                  id: this.currGoodsRow.id, //明细ID
                  delivery_num: this.currGoodsRow.delivery_num_curr, //本次收货数量
                  delivery_money: this.currGoodsRow.delivery_money_curr, //本次收货价格
                  total_money: this.currGoodsRow.total_money_curr, // 本次收货小计
                },
              ]
            };
            this.receiveFun(data)
          }
        }).catch(() => {});
      },
      // 单条全部收货
      async handeReceiveAll(row) {
        // 待发货或待收货状态可编辑时先调编辑保存接口
        if(this.info.status == 0 || this.info.status == 1) {
          await this.handleEditInfo();
        }
        //则最后一条数据收货需要上传单据 (未上传单据不可以全部收货)
        let arr = this.tableData.filter(v => v.status != 5 ) // 过滤掉收货完成的商品
        if(arr.length == 1 && this.is_bills == false) {
          this.$message.warning("未上传采购单据，请先上传单据")
          return
        }
        if(!row.delivery_money_curr) {
          this.$message.warning("请先输入本次收货金额！")
          return
        }
        this.$confirm('请确认收货操作', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.currGoodsRow = row;
          this.receiveType = 2; // 2单条全部收货
          if(this.info.stash_id == 0){ // 如果没有仓库先选仓库
            this.dialogVisible = true;
          } else {
            let data = {
              id: this.currRow.id,
              type: this.receiveType,  //类型 1:单条部分收货 2:单条全部收货
              stash_id: this.form.data.stash_id || '',
              spec: [
                {
                  id: this.currGoodsRow.id, //明细ID
                  delivery_num: this.currGoodsRow.delivery_num_curr, //本次收货数量
                  delivery_money: this.currGoodsRow.delivery_money_curr, //本次收货价格
                  total_money: this.currGoodsRow.total_money_curr, // 本次收货小计
                },
              ]
            };
            this.receiveFun(data);// 收货方法
          }
        }).catch(() => {});
      },
      // 选择仓库后确定
      handeReceiveAllFun() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            var data = {}
            if(this.multipleSelection.length > 0) { // 批量部分收货
              let _spec = this.multipleSelection.map(v => {
                return {
                  id: v.id, //明细ID
                  delivery_num: !!v.delivery_num_curr ? v.delivery_num_curr : 0, //本次收货数量
                  delivery_money: v.delivery_money_curr, //本次收货价格
                  total_money: v.total_money_curr, // 本次收货小计
                }
              }) 
              data = {
                id: this.currRow.id,
                type: this.receiveType,  //类型 1:部分收货 2:全部收货
                stash_id: this.form.data.stash_id, // 仓库id
                spec: _spec
              }
            } else {
              data = {
                id: this.currRow.id,
                type: this.receiveType,  //类型 1:部分收货 2:全部收货
                stash_id: this.form.data.stash_id || '',
                spec: [
                  {
                    id: this.currGoodsRow.id, //明细ID
                    delivery_num: this.currGoodsRow.delivery_num_curr, //本次收货数量
                    delivery_money: this.currGoodsRow.delivery_money_curr, //本次收货价格
                    total_money: this.currGoodsRow.total_money_curr, // 本次收货小计
                  },
                ]
              };
            }
            this.receiveFun(data); // 收货方法
          }
        })
      },
      // 收货方法
      receiveFun(_params) {
        this.$http.post("/admin/purchase_order/receive", _params).then(res => {
          if(res.code == 1) {
            this.dialogVisible = false;
            this.$message.success("操作成功！")
            this.getTable();
          }
        })
      },
      // 计算价格
      countPrice(row,index) {
        // 部分收货状态且已部分收货的商品，收货数量允许填负数
        if(row.status == 4) {
          const reg2 = /^-?\d+\.?\d*$/;
          if (row.delivery_num_curr && !reg2.test(row.delivery_num_curr)) {
            row.delivery_num_curr = row.delivery_num_curr.toString().replace(/[^0-9-]/g, '');
          }
        } else {
          const reg = /^[\d]+$/;
          if (row.delivery_num_curr && !reg.test(row.delivery_num_curr)) {
            row.delivery_num_curr = row.delivery_num_curr.toString().replace(/[^0-9.]/g, '');
          }
          if (row.delivery_money_curr && !reg.test(row.delivery_money_curr)) {
            row.delivery_money_curr = row.delivery_money_curr.replace(/[^0-9.]/g, '');
          }
        }
        // 计算本次收货价格
        if(!!row.delivery_money_curr && !!row.delivery_num_curr) {
          row.total_money_curr = Math.round(parseFloat(row.delivery_num_curr * row.delivery_money_curr)*100)/100;  
          this.$set(this.tableData, index, row)
        }
        // 计算本次收货数量总计和本次收货金额总计
        this.totalNumAll = this.countNum(this.tableData);
        this.totalMoneyAll = this.countMoney(this.tableData);

        this.actionFun(row)
      },
      // 计算本次收货金额
      totalPrice(row,index) {
        const reg = /^[\d]+$/;
        if (row.total_money_curr && !reg.test(row.total_money_curr)) {
          row.total_money_curr = row.total_money_curr.replace(/[^0-9.]/g, '');
        }
        // 计算本次收货数量总计和本次收货金额总计
        this.totalNumAll = this.countNum(this.tableData);
        this.totalMoneyAll = this.countMoney(this.tableData);
        this.actionFun(row)
      },
      // 批量选择数据
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      countNum(arr) {
        let sum = 0;
        arr.map(function (item) {
          if(!!Number(item.delivery_num_curr)) {
            sum += Number(item.delivery_num_curr)
          }
        })
        return sum.toFixed(2) * 1
      },
      countMoney(arr) {
        let sum = 0;
        arr.map(function (item) {
          if(!!Number(item.total_money_curr)) {
            sum += parseFloat((Number(item.total_money_curr)) * 1000)/1000
          }
        })
        return sum.toFixed(2) * 1
      },
      // 批量部分收货
      async handleBatch() {
        // 待发货或待收货状态可编辑时先调编辑保存接口
        if(this.info.status == 0 || this.info.status == 1) {
          await this.handleEditInfo();
        }
        if(this.multipleSelection.length > 0) {
          let num = this.countNum(this.multipleSelection) // 计算收货总数量
          let money = this.countMoney(this.multipleSelection); //计算收货总金额
          let is_money = this.multipleSelection.some((item) => !item.delivery_money_curr); // 检测提交的数据是否填了数量或金额
          let is_num = this.multipleSelection.some((item) => !item.delivery_num_curr); // 检测提交的数据是否填了数量或金额
          if(is_money == true) {
            this.$message.warning("请输入商品规格收货金额")
            return
          }
          if(is_num == true) {
            this.$message.warning("请输入商品规格收货数量")
            return
          }
          // 部分收货状态且已部分收货的商品，收货数量允许填负数且（本次收货数量 + 已收货数量不能小于0）
          let str = this.multipleSelection.some(v => (v.status == 4 && (Number(v.delivery_num_curr) + Number(v.delivery_num) ) < 0))
          if(str) {
            this.$message.warning("单个商品累计收货数量不能小于0")
            return
          }
          this.$confirm(`已收货${this.multipleSelection.length}个商品，数量${num}，金额${money}元`, '确认部分收货吗', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            center: true
          }).then(() => {
            this.receiveType = 1;
            if(this.info.stash_id == 0) { // 若没有仓库先选仓库
              this.dialogVisible = true;
            } else {
              let _spec = this.multipleSelection.map(v => {
                return {
                  id: v.id, //明细ID
                  delivery_num: v.delivery_num_curr, //本次收货数量
                  delivery_money: v.delivery_money_curr, //本次收货价格
                  total_money: v.total_money_curr, // 本次收货小计
                }
              }) 
              let data = {
                id: this.currRow.id,
                type: 1,  //类型 1:批量部分收货 2:批量全部收货
                stash_id: this.form.data.stash_id, // 仓库id
                spec: _spec
              }
              this.receiveFun(data); // 收货方法
            }
          }).catch(() => {});
        } else {
          this.$message.warning('请至少选择一条数据！')
        }
      },
      selectEnable(row) {
        if(row.status != 5) {
          return true
        }
      },
      // 返回
      handleReturn() {
        this.searchForm.data.address_id = '';
        this.searchForm.data.goods_name ='';
        this.$emit("refresh")
        this.toggle(false)
      },
      handleClose() {
        this.dialogVisible = false;
        this.dialogVisibleUrl = false;
        this.dialogVisibleUnit = false;
        this.dialogVisibleBill = false;
        this.imgFileList = [];
        this.fileList = [];
      },
      // 获取计件单位
      getUnit() {
        this.$http.get('/admin/unit/list', {params:{page:1,count:1000}}).then(res => {
          if(res.code === 1) {
            this.unitArr = res.data.list;
          }
        })
      },
      // 修改采购单位
      openPopover(row,index) {
        this.dialogVisibleUnit = true;
        this.currGoodsRow = row;// 当前操作行
        this.currIndex = index;
        this.formUnit.data.cov_unit = row.cov_unit;
      },
      // 修改采购单位
      handleUnit() {
        this.$refs.elFormDomUnit.validate((valid) => {
          if(valid) {
            let _params = {
              detail_id: this.currGoodsRow.id, // 明细id
              cov_unit: this.formUnit.data.cov_unit,
              cov_times: this.formUnit.data.cov_times,
            }
            this.$http.post('/admin/purchase_order/updateUnit', _params).then(res => {
              if(res.code === 1) {
                this.$message.success("修改成功！")
                // 修改采购单位后不自动刷新接口，
                this.tableData[this.currIndex].cov_unit = this.formUnit.data.cov_unit;
                this.tableData[this.currIndex].cov_times = this.formUnit.data.cov_times;
                this.$set(this.tableData[this.currIndex], this.currIndex, this.currRow)
                // this.getTable();
                this.formUnit.data.cov_unit = '';
                this.formUnit.data.cov_times = '';

                this.actionFun(this.currGoodsRow)
              }
            }).finally(() => {
              this.dialogVisibleUnit = false;
            })
          }
        })
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisibleUrl = true;
      },
      // 图片上传成功
      handleAvatarSuccess(res, file) {
        this.formBill.data.bills = res.data.all_path_url;
        let strImg = {
          url: res.data.all_path_url,
          name: ''
        }
        this.fileList.push(strImg)
        this.$refs.elFormDomBill.clearValidate();
      },
      handleRemove2(file, fileList) {
        if(this.fileList.length > 0) {
          this.fileList.forEach((v, i) => {
            if(file.uid === v.uid) {
              this.fileList.splice(i,1)
            }
          })
        }
      },
      // 图片上传之前
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.warning('图片尺寸超出5M，请修改');
        }
        return isLt5M;
      },
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制最多只能选择 6 个文件`);
      },
      handleBill() {
        this.dialogVisibleBill = true;
        this.formBill.data.bind_id = '';
        this.fileList = [];
      },
      uploadFile(file) {
        this.imgFileList.push(file.file)
      },
      // 上传单据
      async sumbitBill() {
        // this.$refs.upload.submit();
        if(this.imgFileList.length < 1) {
          return this.$message.warning('请上传单据')
        }
        var formData = new FormData();
        this.imgFileList.forEach(v => {
          formData.append('file[]',v)
        })
        this.loading = true;
        this.loadingBill = true;
        await this.$http.post('/common/qiniu/upload_multiple', {formData, type:'upload'}).then(res => {
          if(res.code == 1) {
            console.log(res.data.path);
            this.fileList = res.data;
          }
        }).finally(() => {
          this.loading = false;
        })
        let _params = {
          id: this.currRow.id,
          bind_id: this.info.supplier_id, // 供应商/采购员
          bills: this.fileList.map(v => v.all_path_url).join("|")
          // bills: this.fileList.map(v => v.url).join("|")
        }
        console.log(_params)
        this.$http.post('/admin/purchase_order/uploadBill', _params).then(res => {
          if(res.code == 1) {
            this.dialogVisibleBill = false;
            this.$message.success("上传成功！")
            this.is_bills = true;
            this.imgFileList = [];
            this.fileList = [];
            // this.getTable(); // 不自动刷新列表接口
          }
        }).finally(() => {
          this.loadingBill = false;
        })
       
      },
      getSpanArr(data) {
        // data 是我们从后台拿到的数据
        for(var i = 0; i < data.length; i++) {
          if(i == 0) {
            this.spanArr.push(1);
            this.pos = 0;
          } else {
            // 判断当前元素与上一个元素是否相同 (商品编码和规格一至的需要合并)
            if((data[i].code == data[i -1].code) && (data[i].spec_name == data[i -1].spec_name) && (data[i].good_name != '')) {
              this.spanArr[this.pos] += 1;
              this.spanArr.push(0);
            } else {
              this.spanArr.push(1);
              this.pos = i;
            }
          }
        }
      },
      // 当前行row、当前列column、当前行号rowIndex、当前列号columnIndex四个属性
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        // 支持分拣供应商且来源为销售订单的数据合并单元格
        if(this.info.is_sorter == 1 && this.info.source == 1 && (this.info.status == 0 || this.info.status == 1)) {
          if(columnIndex < 8  && columnIndex > 1) { 
            const _row = this.spanArr[rowIndex];
            const _col = _row > 0 ? 1 : 0;
            // console.log(`rowSpan:${_row}, colspan:${_col}`)
            return {
              // [0,0] 表示这一行不显示，[2,1]标示这一行的合并数
              rowspan: _row,
              colspan: _col,
            }
          }
        } else if(this.info.is_sorter == 1 && this.info.source == 1) {
          if(columnIndex < 7  && columnIndex > 0) {
            const _row = this.spanArr[rowIndex];
            const _col = _row > 0 ? 1 : 0;
            // console.log(`rowSpan:${_row}, colspan:${_col}`)
            return {
              // [0,0] 表示这一行不显示，[2,1]标示这一行的合并数
              rowspan: _row,
              colspan: _col,
            }
          }
        }
      },
      numREG_EXP(row,index) {
        const reg = /^[\d]+$/;
        // 下单数量单位只有是（克、两、斤、千克）的时候可以输小数点一位小数，其他的请控制不允许输小数点
        if(row.unit_name == '克' || row.unit_name == '两' || row.unit_name == '斤' || row.unit_name == '千克') {
          if (row.num && !reg.test(row.num)) {
            row.num = row.num=row.num.match(/\d+(\.\d{0,1})?/) ? row.num.match(/\d+(\.\d{0,1})?/)[0] : '';
          }
        } else{
          if (row.num && !reg.test(row.num)) {
            row.num = row.num.replace(/[^\d]/g, '');
          }
        }
        row.delivery_num_curr = !!row.delivery_num_curr ? row.delivery_num_curr : row.num; // 收货数量
        row.total_money_curr = (row.delivery_num_curr * row.last_purchase_price).toFixed(2) * 1, // 本次收货金额（小计）= 本次收货数量 * 本次收货价格
        
        this.$set(this.tableData, index, row)
        this.totalNumAll = this.countNum(this.tableData);
        this.totalMoneyAll = this.countMoney(this.tableData);

        this.actionFun(row)
      },
      // 减
      onMinus(row,index) {
        console.log(row,index)
        if(this.tableData.length > 1) {
          this.tableData.forEach((v,i) => {
            if(index == i) {
              this.tableData.splice(i, 1)
            }
          })
          console.log(this.specArr,"this.specArr,")
          this.specArr = this.specArr.filter(j => j != row.id);
        } else {
          this.$message.warning('最后一条数据了!')
        }

        this.pos = 0; // 初始化合并单元格数据
        this.spanArr = []; // 初始化合并单元格数据
        this.getSpanArr(this.tableData)
        this.table.total -= 1; // 商品项数 减1
        this.totalNumAll = this.countNum(this.tableData); //计划采购总数量
        this.totalMoneyAll = this.countMoney(this.tableData);

        // 删除时向原数据中减一条数据
        this.backupTableData.forEach((v,i) => {
          if(v.spec_id == row.spec_id) {
            this.backupTableData.splice(i, 1)
          }
        })
      },
      // 加
      onAdd(row,index) {
        let str = {
          image: require('@/assets/img/default.jpg'),
          good_name:'',
          num:'',
          address_id: '',
          remarks: [{context:''}],
          id:0,
          extent_curr:0,
        }
        this.tableData.push(str)
        this.$nextTick(() => {
          this.$refs[`multiSelect+${index + 1}`].focus()
        })
        this.goodArr = []; //清除上次的查询商品数据
        this.pos = 0; // 初始化合并单元格数据
        this.spanArr = []; // 初始化合并单元格数据
        this.getSpanArr(this.tableData)
        this.table.total += 1; // 商品项数 加1
      },
      remoteMethod(query) {
        if(query !== '') {
          this.selLoading = true;
          let _params = {
            province: this.form.data.area[0],
            city: this.form.data.area[1],
            page: 1,
            count: 1000,
            status:1,
            good_name: query
          }
          this.$http.get('/admin/goods/specList', { params:_params}).then(res => {
            if(res.code === 1) {
              this.goodArr = res.data.list;
            }
          }).finally(() => {
            this.selLoading = false;
          })
        } else {
          this.goodArr = [];
        }
      },
      // 选择商品
      changeGoods(value, index) {
        let newSpecid = []
        newSpecid = this.tableData.map(v => { return v.id })
        this.specArr = newSpecid;
        if(this.specArr.indexOf(value.id) >= 0) {
          this.$message.warning("已经添加过该规格");
          this.tableData[index].good_name = {}; // 当前行搜索值清空
          this.goodArr = []; // 当前行筛选数据清空
          return;
        }
        this.specArr.push(value.id);
        let _currRow = {
          ...value,
          detail_id:0,
          address_id:'',
          spec_id: value.id, // 规格id
          last_purchase_price:value.purchase_price, // 采购价
          num: '', // 下单数量
          delivery_num_curr: 0, // 本次收货数量
          delivery_money_curr: value.purchase_price, // 本次收货单价
          extent_curr:0, 
          remarks:[{context:''}], // 备注
          cov_unit: value.unit_name, // 单位
          cov_times:0,
        };
        this.tableData[index] = _currRow;
        this.$set(this.tableData, index, {..._currRow})
        this.backupTableData.push(_currRow); // 新增时向原数据中加一条数据
      },
      // 操作列表时同步原数据
      actionFun(row) {
        this.backupTableData.map((item,index) => {
          if(item.spec_id == row.spec_id && (item.address_id == row.address_id || item.address_id == 0)) {
            this.backupTableData[index] = row;
          }
        });
      },
      // 保存编辑
      handleEditInfo() {
        console.log(this.tableData,"11")
        console.log(this.backupTableData,"12")
        let is_id = this.backupTableData.some((item) => !item.id); // 检测提交的数据是否选了商品
        let is_num = this.backupTableData.some((item) => !item.num); // 检测提交的数据是否填了商品规格购买数量
        let is_address_id = this.backupTableData.some((item) => !item.address_id); // 检测提交的数据是否选了门店
        if(is_id == true) {
          this.$message.warning("请选择要添加的商品")
          return
        }
        // 如果支持待分拣需要选择门店
        if(is_address_id == true && this.info.is_sorter == 1) {
          this.$message.warning("请选择食堂名称")
          return
        }
        if(is_num == true) {
          this.$message.warning("请输入商品规格下单数量")
          return
        }
        let _spec = this.backupTableData.map(v => {
          return {
            spec_id: v.spec_id, //规格ID
            address_id: !!v.address_id ? v.address_id : '', //门店ID
            num: v.num, //购买数量
            remark: v.remarks.length > 0 ? v.remarks.filter(item => !!item.context).flatMap(j => Object.values(j)) : '' // 获取备注值不为空时，数组value集
          }
        })
        console.log(_spec)
        let _params = {
          id: this.info.id,
          spec: _spec
        }
        console.log(_params,"_params")
        // return
        this.$http.post('/admin/purchase_order/edit', _params).then(res => {
          if(res.code == 1) {
            // this.$message.success('操作成功！')
            let _params = {
              id: this.currRow.id,
              page: 1,
              count: 1000
            }
            this.$http.get('/admin/purchase_order/info', { params: _params}).then(res => {
              if(res.code == 1) {
                this.tableData.map(item => {
                  if(item.detail_id == 0){
                    res.data.details.list.forEach(val => {
                      if(val.spec_id == item.spec_id && val.address_id == item.address_id){
                        item.id = val.id;
                      }
                    })
                  }
                });
              }
            })
          }
        })
      },
    }
  }
</script>


<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
} 
.addPerson__wrap .main {
  overflow-x: hidden;
  background: #fff;
}
.returnBtn {
  z-index: 99;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
}
.footerWrap {
  text-align: center;
  padding: 10px;
   box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
  z-index: 99;
}
.itemTime {
  display: block;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.flex {
  display: flex;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.width200px {
  width: 200px;
}
.after-table {
  padding: 10px;
  font-size: 13px;
  color: #303030;
  text-align: right;
}
.extent {
  font-size: 10px;
}
.name {
  font-size: 14px;
}
.time {
  margin-left: 30px;
  color: #999;
  font-size: 14px;
}
.imgWrap {
  max-height: 180px;
  overflow: hidden;
}
.imgWrap ::v-deep .el-upload--picture-card {
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
}
.imgWrap ::v-deep .el-upload-list__item{
  width: 80px !important;
  height: 80px !important;
}
// .selected-row {
//   background-color: #f5f7fa !important;
// }

::v-deep .el-table tbody tr:hover>td {
  background-color:#fff !important
}
.el-table tbody tr:hover>td {
  background-color:#fff !important
}
.el-select-dropdown__item {
  display: flex;
  flex-direction: column;
  height: auto !important;
}
</style>
